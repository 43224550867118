.sb-input-box {
    display: inline-flex;
    width: 100%;
}

.sb-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.sb-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.sb-input-box {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 100%;
}

.sb-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.sb-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0 10px;
    grid-auto-rows: 10px;
    justify-items: center;
}

.image-list img {
    width: 250px;
}

.description {
    padding-bottom: 15px;
    width: 250px;
    text-align: center;
}

